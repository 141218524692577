import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Accordion from '../components/Accordion'
import CallOut from '../components/CallOut'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import CMSModal from "../components/CMSModal.jsx"
import FactBox from '../components/FactBox'
import FlipCard from '../components/FlipCard'
import FlipCardContainer from '../components/FlipCardContainer'
import HeaderWithImage from '../components/HeaderWithImage'
import HTTTYP from '../components/HTTTYP'
import LinkButton from '../components/LinkButton'
import Question from '../components/Question'
import Quiz from '../components/Quiz'
import SectionTemplate from '../components/SectionTemplate'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import TextCarousel from '../components/TextCarousel'
import TextSlide from '../components/TextSlide'
import './example.scss'


export default function Example({data}) {
  const shortcodes = { Card, CardContainer, FactBox, Accordion, Carousel, 
    Slide, HeaderWithImage, ShowMore, FlipCard, TextCarousel, TextSlide, 
    LinkButton, Quiz, Question, FlipCardContainer, CMSModal, HTTTYP, CallOut}
  return (
    <div className="Example">
      <SectionTemplate title="Example page with markdown">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </MDXProvider>
      </SectionTemplate>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    mdx(slug: {eq: "example"}) {
      body
    }
  }
`