import Markdown from 'markdown-to-jsx';
import React, { useRef, useState } from 'react';
import Question from './Question';
import './Quiz.scss';


const Quiz = ({question, preamble, children}) => {

  const [questionIndex, setQuestionIndex] = useState(null)
  const questionRef=useRef()

  function nextQuestionHandler() {
    setQuestionIndex(questionIndex + 1)
    questionRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  
  return (
    <div className='Quiz'>
      { questionIndex === null ?
      <div className="ask">
        <Markdown>
          {question}
        </Markdown>
        <div className="preamble">
          <Markdown>
            {preamble}
          </Markdown>
        </div>
        <button 
          id="get-started" 
          onClick={() => setQuestionIndex(0)}
        >
          GET STARTED!
        </button>
      </div>
      :
        <div ref={questionRef}>
          <Question 
            title={children[questionIndex].props.title}
            setup={children[questionIndex].props.setup}
            question={children[questionIndex].props.question}
            options={children[questionIndex].props.options}
            answer={children[questionIndex].props.answer}
            answerIntro={children[questionIndex].props.answerIntro}
            nextHandler={nextQuestionHandler} 
            showNextScenario={questionIndex < children.length - 1}
          />
        </div>
      }
    </div>

  )
}

export default Quiz